import * as React from "react";

import { FormSpec, localized, multiSelectStylePicker } from "../../../form-specs";
import {
    LocalizedLabelOptions,
    LocalizedTitleOptions,
    getWidgetLabelOptions,
    getWidgetTitleOptions,
    labelStylingOptions,
    titleStylingOptions,
} from "../../../components/widgetTitleAndLabel/localizedLableTitle.util";

import { DynamicBedroomBathroomFacet } from "./BedroomBathroom";
import { LocalizedContentBase } from "@maxxton/cms-api";
import { PageWidgetSpec } from "../../";
import { WidgetGroup } from "../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";

export interface WidgetOptions extends LocalizedTitleOptions, LocalizedLabelOptions {
    isMinBedroomShown: boolean;
    isMaxBedroomShown: boolean;
    isMinBathroomShown: boolean;
    isMaxBathroomShown: boolean;
    styleIds: string[];
    maxBedroomLimit?: number;
    maxBathroomLimit?: number;
    useAsPopup: boolean;
    useAsExactMatch: boolean;
    forBathroomExactMatch: boolean;
    forBedroomExactMatch: boolean;
    displayAs: string;
    closeIcon: boolean;
    displayBtn: boolean;
    showArrow: boolean;
    arrowColor: string;
    addIcon: boolean;
    iconOutside: boolean;
    iconRight: boolean;
    displayModalPopup: boolean;
    iconColor: string;
    showErrorInWidget: boolean;
    textTitleColor?: string;
    useAsNameListDropdown: boolean;
    showApplyClear: boolean;
    textLabelColor?: string;
    hideZeroBedroomOptions?: boolean;
    hideZeroBathroomOptions?: boolean;
    localized?: LocalizedBedBathOptions[];
}

interface LocalizedBedBathOptions extends LocalizedContentBase {
    noBedroomText: string;
    noBathroomText: string;
}

const TARGETS = ["bedroomBathroom"];

export const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "dynamic-bathroom-widget",
    name: getI18nLocaleObject(namespaceList.dynamicBedroomBathroom, "bedroomBathroom"),
    pluralName: getI18nLocaleObject(namespaceList.dynamicBedroomBathroom, "bedroomBathrooms"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            {
                                variable: "useAsNameListDropdown",
                                label: getI18nLocaleObject(namespaceList.dynamicBedroomBathroom, "useAsNameListDropdown"),
                                type: "checkbox",
                            },
                            {
                                variable: "showApplyClear",
                                label: getI18nLocaleObject(namespaceList.admin, "showApplyClear"),
                                default: false,
                                type: "checkbox",
                                visible: (item: WidgetOptions) => item.useAsNameListDropdown,
                            },
                            {
                                variable: "useAsExactMatch",
                                label: getI18nLocaleObject(namespaceList.dynamicBedroomBathroom, "useAsExactMatch"),
                                type: "checkbox",
                            },
                            {
                                variable: "forBedroomExactMatch",
                                label: getI18nLocaleObject(namespaceList.dynamicBedroomBathroom, "forBedroomExactMatch"),
                                type: "checkbox",
                                default: false,
                                visible: (item: WidgetOptions) => item.useAsExactMatch,
                                groupName: "bedroomsGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.dynamicBedroomBathroom, "bedroomOptions"),
                            },
                            {
                                variable: "hideZeroBedroomOptions",
                                label: getI18nLocaleObject(namespaceList.dynamicBedroomBathroom, "hideZeroBedroomOptions"),
                                type: "checkbox",
                                visible: (item: WidgetOptions) => !!item.useAsNameListDropdown && !!item.useAsExactMatch && !!item.forBedroomExactMatch,
                                groupName: "bedroomsGroup",
                            },
                            {
                                variable: "isMinBedroomShown",
                                label: getI18nLocaleObject(namespaceList.dynamicBedroomBathroom, "isMinBedroomShown"),
                                type: "checkbox",
                                visible: (item: WidgetOptions) => !item.useAsExactMatch,
                                groupName: "bedroomsGroup",
                            },
                            {
                                variable: "isMaxBedroomShown",
                                label: getI18nLocaleObject(namespaceList.dynamicBedroomBathroom, "isMaxBedroomShown"),
                                default: false,
                                type: "checkbox",
                                visible: (item: WidgetOptions) => !item.useAsExactMatch && !item.useAsNameListDropdown,
                                groupName: "bedroomsGroup",
                            },
                            {
                                variable: "maxBedroomLimit",
                                label: getI18nLocaleObject(namespaceList.dynamicBedroomBathroom, "maxBedroomLimit"),
                                type: "number",
                                visible: (item: WidgetOptions) => {
                                    if ((item.useAsExactMatch && item.forBedroomExactMatch) || (item.useAsNameListDropdown && item.forBedroomExactMatch) || !item.useAsExactMatch) {
                                        return true;
                                    }
                                    return false;
                                },
                                groupName: "bedroomsGroup",
                            },
                            localized({
                                variable: "localized",
                                tabContent: [
                                    {
                                        variable: "noBedroomText",
                                        label: getI18nLocaleObject(namespaceList.dynamicBedroomBathroom, "noBedroomText"),
                                        type: "text",
                                    },
                                ],
                                visible: (options: WidgetOptions) => options.useAsNameListDropdown,
                                groupName: "bedroomsGroup",
                            }),
                            {
                                variable: "forBathroomExactMatch",
                                label: getI18nLocaleObject(namespaceList.dynamicBedroomBathroom, "forBathroomExactMatch"),
                                type: "checkbox",
                                default: false,
                                visible: (item: WidgetOptions) => item.useAsExactMatch,
                                groupName: "bathroomsGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.dynamicBedroomBathroom, "bathroomOptions"),
                            },
                            {
                                variable: "hideZeroBathroomOptions",
                                label: getI18nLocaleObject(namespaceList.dynamicBedroomBathroom, "hideZeroBathroomOptions"),
                                type: "checkbox",
                                visible: (item: WidgetOptions) => !!item.useAsNameListDropdown && !!item.useAsExactMatch && !!item.forBathroomExactMatch,
                                groupName: "bathroomsGroup",
                            },
                            {
                                variable: "isMinBathroomShown",
                                label: getI18nLocaleObject(namespaceList.dynamicBedroomBathroom, "isMinBathroomShown"),
                                type: "checkbox",
                                visible: (item: WidgetOptions) => !item.useAsExactMatch,
                                groupName: "bathroomsGroup",
                            },
                            {
                                variable: "isMaxBathroomShown",
                                label: getI18nLocaleObject(namespaceList.dynamicBedroomBathroom, "isMaxBathroomShown"),
                                default: false,
                                type: "checkbox",
                                visible: (item: WidgetOptions) => !item.useAsExactMatch && !item.useAsNameListDropdown,
                                groupName: "bathroomsGroup",
                            },
                            {
                                variable: "maxBathroomLimit",
                                label: getI18nLocaleObject(namespaceList.dynamicBedroomBathroom, "maxBathroomLimit"),
                                type: "number",
                                visible: (item: WidgetOptions) => {
                                    if ((item.useAsExactMatch && item.forBathroomExactMatch) || (item.useAsNameListDropdown && item.forBathroomExactMatch) || !item.useAsExactMatch) {
                                        return true;
                                    }
                                    return false;
                                },
                                groupName: "bathroomsGroup",
                            },
                            localized({
                                variable: "localized",
                                tabContent: [
                                    {
                                        variable: "noBathroomText",
                                        label: getI18nLocaleObject(namespaceList.dynamicBedroomBathroom, "noBathroomText"),
                                        type: "text",
                                    },
                                ],
                                visible: (options: WidgetOptions) => options.useAsNameListDropdown,
                                groupName: "bathroomsGroup",
                            }),
                            ...getWidgetTitleOptions<WidgetOptions>(),
                            ...getWidgetLabelOptions<WidgetOptions>(),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "style"),
                    properties: [
                        [
                            {
                                variable: "useAsPopup",
                                label: getI18nLocaleObject(namespaceList.dynamicBedroomBathroom, "useAsPopup"),
                                default: false,
                                type: "checkbox",
                                visible: (item: WidgetOptions) => !item.useAsNameListDropdown,
                            },
                            {
                                variable: "displayModalPopup",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "displayModalPopup"),
                                type: "checkbox",
                                visible: (item: WidgetOptions) => !item.useAsNameListDropdown,
                            },
                            {
                                variable: "displayAs",
                                label: getI18nLocaleObject(namespaceList.dynamicBedroomBathroom, "displayAs"),
                                optionList: [
                                    {
                                        label: getI18nLocaleObject(namespaceList.dynamicBedroomBathroom, "displayAsCounter"),
                                        value: "displayAsCounter",
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.dynamicBedroomBathroom, "displayAsOptionsList"),
                                        value: "displayAsOptionsList",
                                    },
                                ],
                                type: "select",
                                default: "displayAsCounter",
                                visible: (item: WidgetOptions) => !item.useAsExactMatch && !item.useAsNameListDropdown,
                            },
                            {
                                variable: "closeIcon",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "closeIcon"),
                                default: false,
                                type: "checkbox",
                                visible: (item: WidgetOptions) => item.useAsPopup,
                            },
                            {
                                variable: "displayBtn",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "displayBtn"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "addIcon",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "addIcon"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.useAsPopup || options.displayModalPopup || options.useAsNameListDropdown,
                            },
                            {
                                variable: "iconColor",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "iconColor"),
                                type: "dual-color",
                                default: "default",
                                visible: (options: WidgetOptions) => (options.addIcon && options.useAsPopup) || options.displayModalPopup || (options.addIcon && options.useAsNameListDropdown),
                            },
                            {
                                variable: "iconOutside",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "iconOutside"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => (options.addIcon && options.useAsPopup) || options.displayModalPopup || (options.addIcon && options.useAsNameListDropdown),
                            },
                            {
                                variable: "iconRight",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "iconRight"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => (options.addIcon && options.useAsPopup) || options.displayModalPopup || (options.addIcon && options.useAsNameListDropdown),
                            },
                            {
                                variable: "showArrow",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "showArrow"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "showErrorInWidget",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "showErrorInWidget"),
                                default: false,
                                type: "checkbox",
                            },
                            multiSelectStylePicker("styleIds", TARGETS),
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "titleParagraph"),
                                type: "paragraph",
                                visible: (options: WidgetOptions) => !!options.enableWidgetTitle,
                            },
                            ...titleStylingOptions<WidgetOptions>({ titleFontColorVariable: "textTitleColor" }),
                            ...labelStylingOptions<WidgetOptions>({ LabelFontColorVariable: "textLabelColor" }),
                        ],
                    ],
                },
            ],
        },
    ],
};

export const bedroomBathroomWidget: PageWidgetSpec<WidgetOptions> = {
    id: "bedroomBathroomWidget",
    type: "page",
    widgetGroup: WidgetGroup.DYNAMIC,
    name: getI18nLocaleObject(namespaceList.dynamicBedroomBathroom, "bedroomBathroom"),
    description: getI18nLocaleObject(namespaceList.dynamicBedroomBathroom, "bedroomBathroomDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        isMinBedroomShown: true,
        isMaxBedroomShown: false,
        isMinBathroomShown: true,
        isMaxBathroomShown: false,
        styleIds: [],
        useAsPopup: false,
        useAsExactMatch: false,
        forBathroomExactMatch: false,
        forBedroomExactMatch: false,
        closeIcon: false,
        displayBtn: false,
        displayAs: "displayAsCounter",
        showArrow: false,
        arrowColor: "default",
        addIcon: false,
        iconColor: "default",
        iconOutside: false,
        iconRight: false,
        displayModalPopup: false,
        showErrorInWidget: false,
        useAsNameListDropdown: false,
        showApplyClear: false,
    }),
    async render(widget, context, sitemapWidgetOptions, resultOptions, dynamicContainerOptions) {
        const styleClasses = findMultiSelectStyleClassNames(context.theme, TARGETS, widget.options.styleIds);

        return <DynamicBedroomBathroomFacet dynamicContainerOptions={dynamicContainerOptions} options={widget.options} context={context} className={styleClasses} />;
    },
};
