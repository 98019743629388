import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";

import { Dropdown, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { dispatchEmptyAction, fetchElasticResponse, getHideWidgetClass, isClientLoggedIn } from "../../../components/utils";
import { getI18nLocaleString, wrapProps } from "../../../i18n";

import { ActionType } from "../../../redux/actions";
import { Alerts } from "../../../alerts";
import { AvailabilityAction } from "../../../redux/actions/availabilityAction";
import { AvailabilityState } from "../../../redux/reducers/availability.types";
import { Dispatch } from "redux";
import { DomainObjectUtil } from "../../../utils/domainobject.util";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { DynamicWidgetBaseProps } from "../dynamicWidget.types";
import { FilterChangeAction } from "../../../redux/actions/dynamicFilterAction.types";
import LocalizedTitleAndLabel from "../../../components/widgetTitleAndLabel/LocalizedLableTitle";
import { OverviewPanelChildOptions } from "../overview-panel";
import { Resource } from "@maxxton/cms-mxts-api";
import { State } from "../../../redux";
import { WidgetOptions } from "./";
import { connect } from "react-redux";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";
import { getLabelOptions } from "../../../components/widgetTitleAndLabel/localizedLableTitle.util";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { getMxtsEnv } from "../../mxts";
import { isEqual } from "lodash";
import namespaceList from "../../../i18n/namespaceList";

interface BedroomBathroomProps extends BedroomBathroomBaseProps, BedroomBathroomStoreProps, BedroomBathroomDispatchProps {}

interface BedroomBathroomBaseProps extends DynamicWidgetBaseProps<WidgetOptions> {
    childOptions?: OverviewPanelChildOptions;
    alerts?: Alerts;
}
interface BedroomBathroomState {
    selectedMinBedroom?: number;
    selectedMaxBedroom?: number;
    disableWidget: boolean;
    selectedMinBathroom?: number;
    selectedMaxBathroom?: number;
    dropdownOpen: boolean;
    finalSelectedMinBedroom?: number;
    finalSelectedMaxBedroom?: number;
    finalSelectedMinBathroom?: number;
    finalSelectedMaxBathroom?: number;
    bathroomMaxLimitErrorAlert: boolean;
    bedroomMaxLimitErrorAlert: boolean;
    bathroomLimitExceedError?: boolean;
    bedroomLimitExceedError?: boolean;
    exactBedroom: number | string;
    exactBathroom: number | string;
    finalExactBedroom: number | string;
    finalExactBathroom: number | string;
    bedBathModalPopup: boolean;
    displayLabel: JSX.Element | null;
    resources: Resource[];
}

interface BedroomBathroomStoreProps {
    dynamicFilter: DynamicFilter;
    availabilityState: AvailabilityState;
}

interface BedroomBathroomDispatchProps {
    dispatchAction: Dispatch<FilterChangeAction | AvailabilityAction>;
}

class BedroomBathroomWidget extends React.Component<BedroomBathroomProps, BedroomBathroomState> {
    constructor(props: BedroomBathroomProps) {
        super(props);
        this.state = {
            dropdownOpen: false,
            disableWidget: true,
            selectedMinBedroom: 0,
            selectedMaxBedroom: 0,
            selectedMinBathroom: 0,
            selectedMaxBathroom: 0,
            bedroomMaxLimitErrorAlert: false,
            bathroomMaxLimitErrorAlert: false,
            exactBathroom: "All",
            exactBedroom: "All",
            finalExactBathroom: "",
            finalExactBedroom: "",
            bedBathModalPopup: false,
            displayLabel: null,
            resources: [],
        };
    }

    public componentDidMount() {
        const {
            dynamicFilter: { minbathroom, minbedroom, maxbathroom, maxbedroom },
            dispatchAction,
        } = this.props;
        dispatchEmptyAction(dispatchAction);
        const { selectedMinBedroom, selectedMaxBedroom, selectedMinBathroom, selectedMaxBathroom } = this.state;
        // If component is remounted and dynamcifilter has values, assigining them here
        this.setState({
            disableWidget: !isClientLoggedIn(),
            selectedMinBedroom: minbedroom || selectedMinBedroom,
            selectedMaxBedroom: maxbedroom || selectedMaxBedroom,
            selectedMinBathroom: minbathroom || selectedMinBathroom,
            selectedMaxBathroom: maxbathroom || selectedMaxBathroom,
        });
    }

    public componentDidUpdate(prevProps: Readonly<BedroomBathroomProps>): void {
        const { options, availabilityState } = this.props;
        const { bedroomOptions, bathroomOptions } = availabilityState.availabilityResult?.response ?? {};
        if (
            !isEqual(prevProps.availabilityState.availabilityResult, this.props.availabilityState.availabilityResult) &&
            options.useAsNameListDropdown &&
            (!bedroomOptions?.length || !bathroomOptions?.length)
        ) {
            this.fetchDynamicBedroomsAndBathrooms();
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Readonly<BedroomBathroomProps>) {
        const { dynamicFilter, availabilityState, context, dispatchAction } = nextProps;
        const { maxbedroom, minbathroom, maxbathroom, minbedroom } = this.props.dynamicFilter;
        let {
            selectedMinBedroom,
            selectedMaxBedroom,
            finalSelectedMinBedroom,
            finalSelectedMaxBedroom,
            selectedMaxBathroom,
            selectedMinBathroom,
            finalSelectedMinBathroom,
            finalSelectedMaxBathroom,
        } = this.state;
        let updateState = false;
        if (!availabilityState.availabilityResult && !availabilityState.fetching) {
            fetchElasticResponse(context, dynamicFilter, dispatchAction, context.currentLocale.code);
        }
        if (dynamicFilter && dynamicFilter.minbedroom !== minbedroom) {
            selectedMinBedroom = dynamicFilter.minbedroom || 0;
            finalSelectedMinBedroom = dynamicFilter.minbedroom || 0;
            updateState = true;
        }
        if (dynamicFilter && dynamicFilter.maxbedroom !== maxbedroom) {
            selectedMaxBedroom = dynamicFilter.maxbedroom || 0;
            finalSelectedMaxBedroom = dynamicFilter.maxbedroom || 0;
            updateState = true;
        }
        if (dynamicFilter && dynamicFilter.minbathroom !== minbathroom) {
            selectedMinBathroom = dynamicFilter.minbathroom || 0;
            finalSelectedMinBathroom = dynamicFilter.minbathroom || 0;
            updateState = true;
        }
        if (dynamicFilter && dynamicFilter.maxbathroom !== maxbathroom) {
            selectedMaxBathroom = dynamicFilter.maxbathroom || 0;
            finalSelectedMaxBathroom = dynamicFilter.maxbathroom || 0;
            updateState = true;
        }

        if (updateState) {
            this.setState({
                selectedMaxBathroom,
                selectedMinBathroom,
                selectedMaxBedroom,
                selectedMinBedroom,
                finalSelectedMinBathroom,
                finalSelectedMaxBedroom,
                finalSelectedMaxBathroom,
                finalSelectedMinBedroom,
            });
        }
    }

    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const {
            selectedMinBathroom,
            selectedMinBedroom,
            selectedMaxBedroom,
            selectedMaxBathroom,
            disableWidget,
            bedroomMaxLimitErrorAlert,
            bathroomMaxLimitErrorAlert,
            bathroomLimitExceedError,
            bedroomLimitExceedError,
            displayLabel,
        } = this.state;
        const {
            childOptions,
            className,
            dynamicFilter,
            options,
            context: { currentLocale, site },
        } = this.props;
        const { fetching } = this.props.availabilityState;
        const hideWidget = getHideWidgetClass(options, disableWidget);
        const localizedWidgetTitle: string = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedWidgetTitle || [], keys: ["widgetTitleText"] })?.widgetTitleText || "";
        const titleOptions = {
            localizedTitle: localizedWidgetTitle,
            enableWidgetTitle: options.enableWidgetTitle,
            useTitleHeadings: options.useTitleHeadings,
            styleWidgetTitle: options.styleWidgetTitle,
            className: classNames("widget-heading", `${options.textTitleColor?.includes("theme") ? `color-${options.textTitleColor}` : ""}`),
            style: options.textTitleColor?.includes("rgba") ? options.textTitleColor : "",
        };
        if (hideWidget === null) {
            return null;
        }
        const labelOptions = getLabelOptions(options, site, currentLocale);
        const layout = (
            <div className={`bed-bath-facet ${hideWidget}`}>
                {bedroomMaxLimitErrorAlert ? <small className="text-danger">{getI18nLocaleString(namespaceList.dynamicBedroomBathroom, "bedroomMaxLimitReach", currentLocale, site)}</small> : null}
                {options.isMinBedroomShown && (
                    <div className="search-window__item">
                        <label className="search-window__label">{getI18nLocaleString(namespaceList.dynamicBedroom, "minBedroom", currentLocale, site)}</label>
                        {options.displayAs === "displayAsOptionsList" ? (
                            <span className="float-right">{this.getBedroomsList(true)}</span>
                        ) : (
                            <span className="search-window__count">{this.getBedrooms(true, selectedMinBedroom, fetching)}</span>
                        )}
                    </div>
                )}
                {bedroomLimitExceedError ? <small className="text-danger">{getI18nLocaleString(namespaceList.dynamicBedroomBathroom, "cantExceedMin", currentLocale, site)}</small> : null}
                {options.isMaxBedroomShown && (
                    <div className="search-window__item">
                        {getI18nLocaleString(namespaceList.dynamicBedroom, "maxBedroom", currentLocale, site)}
                        {options.displayAs === "displayAsOptionsList" ? (
                            <span className="float-right">{this.getBedroomsList(false)}</span>
                        ) : (
                            <span className="search-window__count">{this.getBedrooms(false, selectedMaxBedroom, fetching)}</span>
                        )}
                    </div>
                )}
                {bathroomMaxLimitErrorAlert ? <small className="text-danger">{getI18nLocaleString(namespaceList.dynamicBedroomBathroom, "bathroomMaxLimitReach", currentLocale, site)}</small> : null}
                {options.isMinBathroomShown && (
                    <div className="search-window__item">
                        {getI18nLocaleString(namespaceList.dynamicBathroom, "minBathroom", currentLocale, site)}
                        {options.displayAs === "displayAsOptionsList" ? (
                            <span className="float-right">{this.getBathroomsList(true)}</span>
                        ) : (
                            <span className="search-window__count">{this.getBathrooms(true, selectedMinBathroom, fetching)}</span>
                        )}
                    </div>
                )}
                {bathroomLimitExceedError ? <small className="text-danger">{getI18nLocaleString(namespaceList.dynamicBedroomBathroom, "cantExceedMin", currentLocale, site)}</small> : null}
                {options.isMaxBathroomShown && (
                    <div className="search-window__item">
                        {getI18nLocaleString(namespaceList.dynamicBathroom, "maxBathroom", currentLocale, site)}
                        {options.displayAs === "displayAsOptionsList" ? (
                            <span className="float-right">{this.getBathroomsList(false)}</span>
                        ) : (
                            <span className="search-window__count">{this.getBathrooms(false, selectedMaxBathroom, fetching)}</span>
                        )}
                    </div>
                )}
            </div>
        );

        const exactMatchLayout = (
            <div className=" bed-bath-facet exact-match">
                {options.forBathroomExactMatch && (
                    <div className="search-window__item d-flex">
                        <label className="search-window__label">{getI18nLocaleString(namespaceList.dynamicBathroom, "bathrooms", currentLocale, site)}</label>
                        {this.getBathroomsList()}
                    </div>
                )}
                {options.forBedroomExactMatch && (
                    <div className="search-window__item d-flex">
                        <span>{getI18nLocaleString(namespaceList.dynamicBedroom, "bedrooms", currentLocale, site)}</span>
                        {this.getBedroomsList()}
                    </div>
                )}
            </div>
        );

        if (childOptions) {
            if (childOptions.isEditable) {
                return layout;
            }
            return dynamicFilter && (dynamicFilter.minbathroom || dynamicFilter.maxbathroom || dynamicFilter.minbedroom || dynamicFilter.maxbedroom) ? (
                <div className={`bathroom-facet bed-bath-facet non-editable ${hideWidget}`}>
                    {options.isMinBedroomShown && dynamicFilter.minbedroom ? (
                        <div className="search-window__item">
                            <label className="search-window__label">{getI18nLocaleString(namespaceList.dynamicBedroom, "minBedroom", currentLocale, site)}</label>
                            <span className="search-window__count">{dynamicFilter.minbedroom}</span>
                        </div>
                    ) : null}
                    {options.isMaxBedroomShown && dynamicFilter.maxbedroom ? (
                        <div className="search-window__item">
                            {getI18nLocaleString(namespaceList.dynamicBedroom, "maxBedroom", currentLocale, site)}
                            <span className="search-window__count">{dynamicFilter.maxbedroom}</span>
                        </div>
                    ) : null}
                    {options.isMinBathroomShown && dynamicFilter.minbathroom ? (
                        <div className="search-window__item">
                            {getI18nLocaleString(namespaceList.dynamicBathroom, "minBathroom", currentLocale, site)}
                            {dynamicFilter.minbathroom}
                        </div>
                    ) : null}
                    {options.isMaxBathroomShown && dynamicFilter.maxbathroom ? (
                        <div className="search-window__item">
                            {getI18nLocaleString(namespaceList.dynamicBathroom, "maxBathroom", currentLocale, site)}
                            {dynamicFilter.maxbathroom}
                        </div>
                    ) : null}
                </div>
            ) : null;
        }

        if (options.useAsNameListDropdown) {
            const displayNameListLabel = this.getExactMatchPopupDisplay();
            const bedroomOptions = this.getBedroomsNameListOptions();
            const bathroomOptions = this.getBathroomsNameListOptions();
            const useForBedrooms = (options.forBedroomExactMatch && !options.forBathroomExactMatch) || (options.isMinBedroomShown && !options.isMinBathroomShown);
            const useForBathrooms = (options.forBathroomExactMatch && !options.forBedroomExactMatch) || (options.isMinBathroomShown && !options.isMinBedroomShown);
            const bedroomLabel: string =
                (!bedroomOptions.length && options.localized ? getLocalizedContent({ site, currentLocale, localizedContent: options.localized, keys: ["noBedroomText"] })?.noBedroomText : undefined) ??
                getI18nLocaleString(namespaceList.dynamicBedroom, "bedrooms", currentLocale, site);
            const bathroomLabel =
                (!bathroomOptions.length && options.localized
                    ? getLocalizedContent({ site, currentLocale, localizedContent: options.localized, keys: ["noBathroomText"] })?.noBathroomText
                    : undefined) ?? getI18nLocaleString(namespaceList.dynamicBathroom, "bathrooms", currentLocale, site);
            const dropdownToggleLabel = useForBedrooms ? bedroomLabel : useForBathrooms ? bathroomLabel : getI18nLocaleString(namespaceList.dynamicBathroom, "bedBath", currentLocale, site);
            return (
                <React.Fragment>
                    <LocalizedTitleAndLabel {...titleOptions} />
                    <div
                        // eslint-disable-next-line max-len
                        className={`dynamic-bed-bath-namelist search-filter-box ${options.displayBtn ? "display-filter-btn" : ""} ${options.addIcon ? "add-icon" : ""} ${
                            options.iconColor && options.iconColor.indexOf("theme") > -1
                                ? `color-${options.iconColor}`
                                : options.iconColor && options.iconColor.indexOf("rgba") === -1
                                ? "icon-" + options.iconColor
                                : ""
                        } ${options.addIcon && options.iconOutside ? "icon-outside" : "icon-inside"} ${className} ${options.addIcon && options.iconRight ? "move-icon-right" : "move-icon-left"} ${
                            options.showArrow ? "show-arrows" : ""
                        }`}
                        style={{ color: options.addIcon && options.iconColor && options.iconColor.indexOf("rgba") > -1 ? options.iconColor : undefined }}
                    >
                        <Dropdown nav className="bedbath-namelist-inner search-filter-box--inner" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                            <DropdownToggle
                                {...{ style: { color: options.addIcon && options.iconColor && options.iconColor.indexOf("rgba") > -1 ? options.iconColor : undefined } }}
                                // eslint-disable-next-line max-len
                                className={`${displayNameListLabel || displayLabel ? "active " : ""} bedbath-label search-filter-box--inner__label ${
                                    (useForBedrooms && !bedroomOptions.length) || (useForBathrooms && !bathroomOptions.length) ? "disabled" : ""
                                }`}
                                nav
                            >
                                {displayLabel || displayNameListLabel || (
                                    <div className="filter-data-wrap">
                                        <LocalizedTitleAndLabel {...labelOptions} />
                                        {dropdownToggleLabel}
                                    </div>
                                )}
                            </DropdownToggle>
                            <DropdownMenu
                                // eslint-disable-next-line max-len
                                className={`bedbath-namelist-dropdown search-filter-box--inner__dropdown ${options.closeIcon ? "close-icon-added" : ""}`}
                            >
                                <div className="bedbath-namelist">
                                    {options.closeIcon ? (
                                        <span className="popup-close" onClick={this.toggle}>
                                            <FontAwesome name="close" />
                                        </span>
                                    ) : null}
                                    {useForBedrooms && <label>{getI18nLocaleString(namespaceList.dynamicBedroom, "bedrooms", currentLocale, site)}</label>}
                                    {useForBedrooms &&
                                        bedroomOptions.map((bed) => (
                                            <div key={`bedroom-${bed}`} className="search-window__item" onClick={this.handleNameListBedroomBathroom.bind(this, true)}>
                                                {bed}
                                            </div>
                                        ))}
                                    {useForBathrooms && <label>{getI18nLocaleString(namespaceList.dynamicBathroom, "bathrooms", currentLocale, site)}</label>}
                                    {useForBathrooms &&
                                        bathroomOptions.map((bath) => (
                                            <div key={`bedroom-${bath}`} className="search-window__item" onClick={this.handleNameListBedroomBathroom.bind(this, false)}>
                                                {bath}
                                            </div>
                                        ))}
                                </div>
                                {options.showApplyClear && (
                                    <div className="filter-action-buttons">
                                        <a className="action-button__clear" role="button" onClick={this.clearExactBedBathResults}>
                                            {getI18nLocaleString(namespaceList.admin, "clear", currentLocale, site)}
                                        </a>
                                        <a className="action-button__apply" role="button" onClick={this.applyExactBedBathResults}>
                                            {getI18nLocaleString(namespaceList.admin, "apply", currentLocale, site)}
                                        </a>
                                    </div>
                                )}
                            </DropdownMenu>
                            <div className={`${this.state.dropdownOpen ? "backdrop-popup" : ""}`} onClick={this.state.dropdownOpen ? this.toggle : undefined}></div>
                        </Dropdown>
                    </div>
                </React.Fragment>
            );
        }

        // If bedroom/bathroom is used for Exact match, it will display a list default format
        if (options.useAsExactMatch && !options.useAsPopup) {
            return exactMatchLayout;
        }

        if (options.useAsPopup) {
            const displayLabel = options.useAsExactMatch ? this.getExactMatchPopupDisplay() : this.getPopupDisplay();
            return (
                <React.Fragment>
                    <LocalizedTitleAndLabel {...titleOptions} />
                    <div
                        // eslint-disable-next-line max-len
                        className={`dynamic-bed-bath search-filter-box ${options.displayBtn ? "display-filter-btn" : ""} ${options.addIcon ? "add-icon" : ""} ${
                            options.iconColor && options.iconColor.indexOf("theme") > -1
                                ? `color-${options.iconColor}`
                                : options.iconColor && options.iconColor.indexOf("rgba") === -1
                                ? "icon-" + options.iconColor
                                : ""
                        } ${options.addIcon && options.iconOutside ? "icon-outside" : "icon-inside"} ${options.addIcon && options.iconRight ? "move-icon-right" : "move-icon-left"} ${
                            options.showArrow ? "show-arrows" : ""
                        }`}
                        style={{ color: options.addIcon && options.iconColor && options.iconColor.indexOf("rgba") > -1 ? options.iconColor : undefined }}
                    >
                        <Dropdown nav className="bed-bath-inner search-filter-box--inner" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                            <DropdownToggle
                                {...{ style: { color: options.addIcon && options.iconColor && options.iconColor.indexOf("rgba") > -1 ? options.iconColor : undefined } }}
                                // eslint-disable-next-line max-len
                                className={`${displayLabel ? "active " : ""} bedbath-label search-filter-box--inner__label`}
                                nav
                            >
                                {displayLabel || (
                                    <div className="filter-data-wrap">
                                        <LocalizedTitleAndLabel {...labelOptions} />
                                        {getI18nLocaleString(namespaceList.dynamicBathroom, "bedBath", currentLocale, site)}
                                    </div>
                                )}
                            </DropdownToggle>
                            <DropdownMenu
                                // eslint-disable-next-line max-len
                                className={`bedbath-dropdown search-filter-box--inner__dropdown ${options.closeIcon ? "close-icon-added" : ""}`}
                            >
                                {options.closeIcon ? (
                                    <span className="popup-close" onClick={this.toggle}>
                                        <FontAwesome name="close" />
                                    </span>
                                ) : null}
                                {options.useAsExactMatch ? exactMatchLayout : layout}
                                <div className="filter-action-buttons">
                                    <a className="action-button__clear" role="button" onClick={options.useAsExactMatch ? this.clearExactBedBathResults : this.clearResults}>
                                        {getI18nLocaleString(namespaceList.admin, "clear", currentLocale, site)}
                                    </a>
                                    <a className="action-button__apply" role="button" onClick={options.useAsExactMatch ? this.applyExactBedBathResults : this.applyResults}>
                                        {getI18nLocaleString(namespaceList.admin, "apply", currentLocale, site)}
                                    </a>
                                </div>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </React.Fragment>
            );
        }
        if (options.displayModalPopup) {
            const displayLabel = options.useAsExactMatch ? this.getExactMatchPopupDisplay() : this.getPopupDisplay();
            return (
                <React.Fragment>
                    <LocalizedTitleAndLabel {...titleOptions} />
                    <div
                        // eslint-disable-next-line max-len
                        className={classNames(
                            `dynamic-bed-bath search-filter-box ${options.displayBtn ? "display-filter-btn" : ""} ${options.addIcon ? "add-icon" : ""} ${
                                options.iconColor && options.iconColor.indexOf("theme") > -1
                                    ? `color-${options.iconColor}`
                                    : options.iconColor && options.iconColor.indexOf("rgba") === -1
                                    ? "icon-" + options.iconColor
                                    : ""
                            } ${options.addIcon && options.iconOutside ? "icon-outside" : "icon-inside"} ${options.addIcon && options.iconRight ? "move-icon-right" : "move-icon-left"} ${
                                options.showArrow ? "show-arrows" : ""
                            }`,
                            className
                        )}
                        style={{ color: options.addIcon && options.iconColor && options.iconColor.indexOf("rgba") > -1 ? options.iconColor : undefined }}
                    >
                        <div className="bed-bath-inner search-filter-box--inner" onClick={this.handleBedBathModal}>
                            <a
                                {...{ style: { color: options.addIcon && options.iconColor && options.iconColor.indexOf("rgba") > -1 ? options.iconColor : undefined } }}
                                // eslint-disable-next-line max-len
                                className={`${displayLabel ? "active " : ""} search-filter-box--inner__label`}
                            >
                                {displayLabel || (
                                    <div className="filter-data-wrap">
                                        <LocalizedTitleAndLabel {...labelOptions} />
                                        {getI18nLocaleString(namespaceList.dynamicBathroom, "bedBath", currentLocale, site)}
                                    </div>
                                )}
                                {this.bedbathModal(exactMatchLayout, layout)}
                            </a>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <LocalizedTitleAndLabel {...titleOptions} />
                {layout}
            </React.Fragment>
        );
    }

    private toggle = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    };

    private fetchDynamicBedroomsAndBathrooms = async () => {
        const { availabilityState } = this.props;
        const { mxtsApi } = this.props.context;
        const env = availabilityState.env || (await getMxtsEnv(this.props.context));
        const resourcesList = availabilityState.availabilityResult?.response.resources;
        const resourceIdList = resourcesList?.map((resource) => resource.resourceId);
        let fetchedResourcesDetails: Resource[] = [];
        if (resourceIdList?.length) {
            fetchedResourcesDetails = await DomainObjectUtil.getResourcesByIds(mxtsApi, resourceIdList, env);
            this.setState({ resources: fetchedResourcesDetails });
        }
    };

    // eslint-disable-next-line max-lines-per-function
    private handleBedroom = (minBedroom: boolean, increaseBedroom?: boolean, event?: React.ChangeEvent<HTMLInputElement>) => {
        const { selectedMinBedroom, selectedMaxBedroom, bedroomMaxLimitErrorAlert, bedroomLimitExceedError } = this.state;
        const { options, context, dispatchAction } = this.props;
        const { currentLocale, site } = context;
        const bedroom = increaseBedroom === undefined && event ? (+event.target.value <= 0 ? undefined : +event.target.value) : undefined;
        if (minBedroom) {
            const selectedMinBedroomNumber: number | undefined =
                increaseBedroom === undefined && event
                    ? bedroom
                    : increaseBedroom
                    ? selectedMinBedroom
                        ? selectedMinBedroom + 1
                        : 1
                    : selectedMinBedroom && selectedMinBedroom > 1
                    ? selectedMinBedroom - 1
                    : 0;
            if (options.maxBedroomLimit && selectedMinBedroomNumber && selectedMinBedroomNumber > +options.maxBedroomLimit) {
                if (context.alerts && !options.showErrorInWidget) {
                    context.alerts.push({
                        color: "danger",
                        message: getI18nLocaleString(namespaceList.dynamicBedroomBathroom, "maxLimitReach", currentLocale, site),
                    });
                } else if (options.showErrorInWidget) {
                    this.setState({ bedroomMaxLimitErrorAlert: true });
                }
                return;
            } else if (bedroomMaxLimitErrorAlert) {
                this.setState({ bedroomMaxLimitErrorAlert: false });
            }

            // min bigger than max
            let setMinBedroom = false;
            if (selectedMinBedroomNumber && (selectedMaxBedroom === 0 || selectedMaxBedroom) && options.isMaxBedroomShown && selectedMinBedroomNumber > selectedMaxBedroom) {
                this.setState({ selectedMaxBedroom: selectedMinBedroomNumber });
                setMinBedroom = true;
            }

            if (!options.useAsPopup) {
                const action: FilterChangeAction = {
                    type: ActionType.FilterChange,
                    filter: setMinBedroom ? dynamicFilterType.maxBedBath : dynamicFilterType.minbedroom,
                    payload: setMinBedroom
                        ? {
                              ...this.props.dynamicFilter,
                              minbedroom: selectedMinBedroomNumber,
                              maxbedroom: selectedMinBedroomNumber,
                          }
                        : {
                              minbedroom: selectedMinBedroomNumber,
                          },
                };
                dispatchAction(action);
            }
            this.setState({ selectedMinBedroom: selectedMinBedroomNumber });
        } /* Max bedroom */ else {
            const selectedMaxBedroomNumber: number | undefined =
                increaseBedroom === undefined && event
                    ? bedroom
                    : increaseBedroom
                    ? selectedMaxBedroom
                        ? selectedMaxBedroom + 1
                        : 1
                    : selectedMaxBedroom && selectedMaxBedroom > 1
                    ? selectedMaxBedroom - 1
                    : 0;
            if (options.maxBedroomLimit && selectedMaxBedroomNumber && selectedMaxBedroomNumber > +options.maxBedroomLimit) {
                if (context.alerts && !options.showErrorInWidget) {
                    context.alerts.push({
                        color: "danger",
                        message: getI18nLocaleString(namespaceList.dynamicBedroomBathroom, "maxLimitReach", currentLocale, site),
                    });
                } else if (options.showErrorInWidget) {
                    this.setState({ bedroomMaxLimitErrorAlert: true });
                }
                return;
            } else if (bedroomMaxLimitErrorAlert) {
                this.setState({ bedroomMaxLimitErrorAlert: false });
            }

            // If max is smaller than min
            if (selectedMaxBedroomNumber && selectedMinBedroom && selectedMaxBedroomNumber < selectedMinBedroom) {
                if (context.alerts && !options.showErrorInWidget) {
                    context.alerts.push({
                        color: "danger",
                        message: getI18nLocaleString(namespaceList.dynamicBedroomBathroom, "cantExceedMin", currentLocale, site),
                    });
                } else if (options.showErrorInWidget) {
                    this.setState({ bedroomLimitExceedError: true });
                }
                return;
            } else if (bedroomLimitExceedError) {
                this.setState({ bedroomLimitExceedError: false });
            }

            // When not popup, onclick of plus/minus, results should be filtered
            if (!options.useAsPopup) {
                const action: FilterChangeAction = {
                    type: ActionType.FilterChange,
                    filter: dynamicFilterType.maxbedroom,
                    payload: {
                        maxbedroom: selectedMaxBedroomNumber,
                    },
                };
                dispatchAction(action);
            }
            this.setState({ selectedMaxBedroom: selectedMaxBedroomNumber });
        }
    };

    private getBedroomsNameListOptions = (): Array<number | string> => {
        const {
            options: { maxBedroomLimit, useAsNameListDropdown, useAsExactMatch, hideZeroBedroomOptions },
            context: { currentLocale, site },
            availabilityState: { availabilityResult },
        } = this.props;
        const { resources } = this.state;

        const bedroomLocale = getI18nLocaleString(namespaceList.dynamicBedroom, "bedrooms", currentLocale, site);

        // If maxBedroomLimit is specified it will create an array of length maxBedroomLimit, otherwise by default an array of length 25
        let bedroomOptions: Array<number | string> = maxBedroomLimit ? Array.from(Array(+maxBedroomLimit).keys()) : Array.from(Array(25).keys());
        const availableBedroomOptions = [...(availabilityResult?.response.bedroomOptions || [])];
        if (useAsNameListDropdown) {
            if (availableBedroomOptions?.length) {
                bedroomOptions = availableBedroomOptions;
            } else if (resources.length) {
                bedroomOptions = [...new Set(resources.flatMap((resource) => (resource.nrOfBedrooms !== null ? (resource.nrOfBedrooms as number) : [])))].sort((a: number, b: number) => a - b);
            } else if (availabilityResult?.response.resortGroup?.length) {
                bedroomOptions = this.getOptionsFromAvailabilityResortGroups("nrOfBedrooms");
            }
            if (useAsExactMatch && hideZeroBedroomOptions) {
                bedroomOptions = bedroomOptions.filter((bedroom) => bedroom !== 0);
            }
            if (bedroomOptions.length) {
                bedroomOptions.unshift(getI18nLocaleString(namespaceList.widgetAssetPublisher, "all", currentLocale, site) + bedroomLocale);
            }
        }
        bedroomOptions.forEach((bed, index) => {
            if (bed === getI18nLocaleString(namespaceList.widgetAssetPublisher, "all", currentLocale, site) + bedroomLocale) {
                return;
            } else if (bed === 0) {
                bedroomOptions[index] = getI18nLocaleString(namespaceList.dynamicBedroomBathroom, "studio", currentLocale, site);
            } else if (bed === 1) {
                bedroomOptions[index] = bed + getI18nLocaleString(namespaceList.dynamicBedroom, "bedroom", currentLocale, site);
            } else {
                bedroomOptions[index] = bed + bedroomLocale;
            }
        });
        return bedroomOptions;
    };

    private getBathroomsNameListOptions = (): Array<number | string> => {
        const {
            options: { maxBedroomLimit, useAsNameListDropdown, hideZeroBathroomOptions, useAsExactMatch },
            context: { currentLocale, site },
            availabilityState: { availabilityResult },
        } = this.props;
        const { resources } = this.state;

        const bathroomLocale = getI18nLocaleString(namespaceList.dynamicBathroom, "bathrooms", currentLocale, site);

        // If maxBedroomLimit is specified it will create an array of length maxBedroomLimit, otherwise by default an array of length 25
        let bathroomOptions: Array<number | string> = maxBedroomLimit ? Array.from(Array(+maxBedroomLimit).keys()) : Array.from(Array(25).keys());
        const availableBathroomOptions = [...(availabilityResult?.response.bathroomOptions || [])];
        if (useAsNameListDropdown) {
            if (availableBathroomOptions?.length) {
                bathroomOptions = availableBathroomOptions;
            } else if (resources.length) {
                bathroomOptions = [...new Set(resources.flatMap((resource) => (resource.nrOfBathrooms !== null ? (resource.nrOfBathrooms as number) : [])))].sort((a: number, b: number) => a - b);
            } else if (availabilityResult?.response.resortGroup?.length) {
                bathroomOptions = this.getOptionsFromAvailabilityResortGroups("nrOfBathrooms");
            }
            if (useAsExactMatch && hideZeroBathroomOptions) {
                bathroomOptions = bathroomOptions.filter((bathroom) => bathroom !== 0);
            }
            if (bathroomOptions.length) {
                bathroomOptions.unshift(getI18nLocaleString(namespaceList.widgetAssetPublisher, "all", currentLocale, site) + bathroomLocale);
            }
        }
        bathroomOptions.forEach((bath, index) => {
            if (bath === getI18nLocaleString(namespaceList.widgetAssetPublisher, "all", currentLocale, site) + bathroomLocale) {
                return;
            } else if (bath === 1) {
                bathroomOptions[index] = bath + getI18nLocaleString(namespaceList.dynamicBathroom, "bathroom", currentLocale, site);
            } else {
                bathroomOptions[index] = bath + bathroomLocale;
            }
        });
        return bathroomOptions;
    };

    private getOptionsFromAvailabilityResortGroups = (propertyName: "nrOfBedrooms" | "nrOfBathrooms") => {
        const {
            availabilityState: { availabilityResult },
        } = this.props;

        const resortGroups = availabilityResult?.response.resortGroup;
        return [
            ...new Set(
                resortGroups?.flatMap((group) =>
                    group.accommodationTypes.flatMap((accommodationType) =>
                        (accommodationType as any)[propertyName] !== null && (accommodationType as any)[propertyName] !== undefined ? ((accommodationType as any)[propertyName] as number) : []
                    )
                )
            ),
        ].sort((a: number, b: number) => a - b);
    };

    private handleNameListBedroomBathroom = (forBedroom: boolean, event: React.ChangeEvent<HTMLSelectElement>) => {
        const { dispatchAction, options } = this.props;
        const {
            context: { currentLocale, site },
        } = this.props;
        let selectedBedBathValue: string | number = "";
        if (
            event.target.innerText ===
                getI18nLocaleString(namespaceList.widgetAssetPublisher, "all", currentLocale, site) + getI18nLocaleString(namespaceList.dynamicBedroom, "bedrooms", currentLocale, site) ||
            event.target.innerText ===
                getI18nLocaleString(namespaceList.widgetAssetPublisher, "all", currentLocale, site) + getI18nLocaleString(namespaceList.dynamicBathroom, "bathrooms", currentLocale, site)
        ) {
            selectedBedBathValue = "All";
        } else if (event.target.innerText === getI18nLocaleString(namespaceList.dynamicBedroomBathroom, "studio", currentLocale, site)) {
            selectedBedBathValue = 0;
            this.setState({ displayLabel: null });
        } else {
            const separatedSelectedValue = event.target.innerText.split(" ");
            selectedBedBathValue = separatedSelectedValue[0];
            this.setState({ displayLabel: null });
        }
        if (!options.showApplyClear) {
            this.toggle();
        }
        if (options.showApplyClear) {
            this.setState({ displayLabel: <span>{event.target.innerText}</span> });
        }
        if (event && selectedBedBathValue === "All") {
            this.setState({ displayLabel: <span>{event.target.innerText}</span> });
            // Reset to default bedrooms
            if (forBedroom) {
                this.setState({ exactBedroom: "All" }, () => {
                    if (!options.showApplyClear) {
                        const bedBathroomFilterAction = this.getBedBathAction(
                            {
                                minbedroom: undefined,
                                maxbedroom: undefined,
                            },
                            dynamicFilterType.removeBedroomFilters
                        );
                        dispatchAction(bedBathroomFilterAction);
                    }
                });
            } else {
                // Clear bathrooms
                this.setState({ exactBathroom: "All" }, () => {
                    if (!options.showApplyClear) {
                        const bedBathroomFilterAction = this.getBedBathAction(
                            {
                                minbathroom: undefined,
                                maxbathroom: undefined,
                            },
                            dynamicFilterType.removeBathroomFilters
                        );
                        dispatchAction(bedBathroomFilterAction);
                    }
                });
            }
            return;
        }

        // Dispatching both min and max at the same time for exact matches
        if (forBedroom && event) {
            this.setState({ exactBedroom: +selectedBedBathValue }, () => {
                if (!options.showApplyClear) {
                    if (options.useAsExactMatch && options.forBedroomExactMatch) {
                        const action = this.getBedBathAction({
                            maxbedroom: this.state.exactBedroom,
                            minbedroom: this.state.exactBedroom,
                        });
                        dispatchAction(action);
                    } else if (options.isMinBedroomShown) {
                        const action = this.getBedBathAction({ minbedroom: selectedBedBathValue });
                        dispatchAction(action);
                    }
                }
            });
        } else {
            // For bathrooms
            this.setState({ exactBathroom: +selectedBedBathValue }, () => {
                if (!options.showApplyClear) {
                    if (options.useAsExactMatch && options.forBathroomExactMatch) {
                        {
                            const action = this.getBedBathAction({
                                maxbathroom: this.state.exactBathroom,
                                minbathroom: this.state.exactBathroom,
                            });
                            dispatchAction(action);
                        }
                    } else if (options.isMinBathroomShown) {
                        const action = this.getBedBathAction({ minbathroom: selectedBedBathValue });
                        dispatchAction(action);
                    }
                }
            });
        }
    };

    private getBedroomsList = (isMinBed?: boolean) => {
        const {
            options: { maxBedroomLimit, useAsExactMatch },
            availabilityState: { fetching, availabilityResult },
            dynamicFilter: { minbedroom },
        } = this.props;
        const { exactBedroom, selectedMaxBedroom, selectedMinBedroom } = this.state;
        const availabilityBedroomList = availabilityResult?.response.bedroomOptions || [];
        const bedroomOptions: Array<number | string> = maxBedroomLimit ? Array.from(Array(+maxBedroomLimit).keys()) : Array.from(Array(25).keys());
        if (useAsExactMatch) {
            bedroomOptions.unshift("All");
        }
        return (
            <select
                onChange={useAsExactMatch ? this.handleExactBedroomBathroom.bind(this, true) : this.handleBedroom.bind(this, isMinBed ? true : false, undefined)}
                value={useAsExactMatch ? (exactBedroom === "All" && minbedroom ? minbedroom : exactBedroom) : isMinBed ? selectedMinBedroom : selectedMaxBedroom}
                disabled={fetching || !availabilityBedroomList.length}
            >
                {bedroomOptions.map((bed: number | string) => (
                    <option key={`bedroom-${bed}`}>{bed}</option>
                ))}
            </select>
        );
    };

    // When Exact bed/bath feature is used, there's no check for apply/clear. Every changes handles a dispatch
    private handleExactBedroomBathroom = (forBedroom: boolean, event: React.ChangeEvent<HTMLSelectElement>) => {
        const { dispatchAction, options } = this.props;

        if (event && event.target.value === "All") {
            // Reset to default bedrooms
            if (forBedroom) {
                this.setState({ exactBedroom: "All" }, () => {
                    // If popup, only dispatch based on Apply click
                    if (!options.useAsPopup) {
                        const bedBathroomFilterAction = this.getBedBathAction(
                            {
                                minbedroom: undefined,
                                maxbedroom: undefined,
                            },
                            dynamicFilterType.removeBedroomFilters
                        );
                        dispatchAction(bedBathroomFilterAction);
                    }
                });
            } else {
                // Clear bathrooms
                this.setState({ exactBathroom: "All" }, () => {
                    if (!options.useAsPopup) {
                        const bedBathroomFilterAction = this.getBedBathAction(
                            {
                                minbathroom: undefined,
                                maxbathroom: undefined,
                            },
                            dynamicFilterType.removeBathroomFilters
                        );
                        dispatchAction(bedBathroomFilterAction);
                    }
                });
            }
            return;
        }

        // Dispatching both min and max at the same time for exact matches
        if (forBedroom && event) {
            this.setState({ exactBedroom: +event.target.value }, () => {
                if (!options.useAsPopup) {
                    const action = this.getBedBathAction({
                        maxbedroom: this.state.exactBedroom,
                        minbedroom: this.state.exactBedroom,
                    });
                    dispatchAction(action);
                }
            });
        } else {
            // For bathrooms
            this.setState({ exactBathroom: +event.target.value }, () => {
                if (!options.useAsPopup) {
                    const action = this.getBedBathAction({
                        maxbathroom: this.state.exactBathroom,
                        minbathroom: this.state.exactBathroom,
                    });
                    dispatchAction(action);
                }
            });
        }
    };

    private getBathroomsList = (isMinBath?: boolean) => {
        const {
            options: { maxBathroomLimit, useAsExactMatch },
            availabilityState: { fetching, availabilityResult },
            dynamicFilter: { minbathroom },
        } = this.props;
        const { exactBathroom, selectedMinBathroom, selectedMaxBathroom } = this.state;
        const availabilityBathroomList = availabilityResult?.response.bathroomOptions || [];
        const bathroomNumbers: Array<number | string> = maxBathroomLimit ? Array.from(Array(+maxBathroomLimit).keys()) : Array.from(Array(25).keys());
        if (useAsExactMatch) {
            bathroomNumbers.unshift("All");
        }
        return (
            <select
                onChange={useAsExactMatch ? this.handleExactBedroomBathroom.bind(this, false) : this.handleBathroom.bind(this, !!isMinBath, undefined)}
                disabled={fetching || !availabilityBathroomList.length}
                value={useAsExactMatch ? (exactBathroom === "All" && minbathroom ? minbathroom : exactBathroom) : isMinBath ? selectedMinBathroom : selectedMaxBathroom}
            >
                {bathroomNumbers.map((bed: number | string) => (
                    <option key={`bedroom-${bed}`}>{bed}</option>
                ))}
            </select>
        );
    };

    private getBedrooms(minBedroom?: boolean, selectedBedroom?: number, fetching?: boolean): JSX.Element {
        const {
            options,
            context: { currentLocale, site },
        } = this.props;
        const { selectedMaxBedroom, selectedMinBedroom } = this.state;
        return (
            <div className={"search-window__count bedroom-wrap"}>
                <div
                    // eslint-disable-next-line max-len
                    className={`search-window__button ${
                        !selectedBedroom ||
                        (minBedroom && selectedMaxBedroom && selectedMinBedroom ? selectedMaxBedroom < selectedMinBedroom : selectedMaxBedroom === selectedMinBedroom && selectedMaxBedroom !== 1)
                            ? "search-window__button--disabled"
                            : ""
                    }`}
                    onClick={!fetching ? this.handleBedroom.bind(this, minBedroom, false) : undefined}
                >
                    {getI18nLocaleString(namespaceList.widgetSearchfacet, "decreaseSign", currentLocale, site)}
                </div>
                <input
                    className="search-window__input"
                    value={selectedBedroom ? selectedBedroom : 0}
                    type="number"
                    onChange={!fetching ? this.handleBedroom.bind(this, minBedroom, undefined) : undefined}
                />
                <div
                    // eslint-disable-next-line max-len
                    className={`search-window__button ${options.maxBedroomLimit && selectedBedroom === +options.maxBedroomLimit ? "search-window__button--disabled" : ""}`}
                    onClick={!fetching ? this.handleBedroom.bind(this, minBedroom, true) : undefined}
                >
                    {getI18nLocaleString(namespaceList.widgetSearchfacet, "increaseSign", currentLocale, site)}
                </div>
            </div>
        );
    }

    private getBathrooms(minBathroom?: boolean, selectedBathroom?: number, fetching?: boolean): JSX.Element {
        const {
            options,
            context: { currentLocale, site },
        } = this.props;
        const { selectedMaxBathroom, selectedMinBathroom } = this.state;
        return (
            <div className={"search-window__count bathroom-wrap"}>
                <div
                    // eslint-disable-next-line max-len
                    className={`search-window__button ${
                        !selectedBathroom ||
                        (minBathroom && selectedMaxBathroom && selectedMinBathroom
                            ? selectedMaxBathroom < selectedMinBathroom
                            : selectedMaxBathroom === selectedMinBathroom && selectedMaxBathroom !== 1)
                            ? "search-window__button--disabled"
                            : ""
                    }`}
                    onClick={!fetching ? this.handleBathroom.bind(this, minBathroom, false) : undefined}
                >
                    {getI18nLocaleString(namespaceList.widgetSearchfacet, "decreaseSign", currentLocale, site)}
                </div>
                <input
                    className="search-window__input"
                    value={selectedBathroom ? selectedBathroom : 0}
                    type="number"
                    max={options.maxBathroomLimit}
                    onChange={!fetching ? this.handleBathroom.bind(this, minBathroom, undefined) : undefined}
                />
                <div
                    // eslint-disable-next-line max-len
                    className={`search-window__button ${options.maxBathroomLimit && selectedBathroom === +options.maxBathroomLimit ? "search-window__button--disabled" : ""}`}
                    onClick={!fetching ? this.handleBathroom.bind(this, minBathroom, true) : undefined}
                >
                    {getI18nLocaleString(namespaceList.widgetSearchfacet, "increaseSign", currentLocale, site)}
                </div>
            </div>
        );
    }

    private handleBedBathModal = () => {
        this.setState({ bedBathModalPopup: !this.state.bedBathModalPopup });
    };

    private bedbathModal = (exactMatchLayout: JSX.Element, layout: JSX.Element) => {
        const {
            options,
            context: { currentLocale, site },
        } = this.props;
        const { bedBathModalPopup, disableWidget } = this.state;
        const hideWidget = getHideWidgetClass(options, disableWidget);

        if (hideWidget === null) {
            return null;
        }
        return (
            <Modal isOpen={bedBathModalPopup} toggle={this.handleBedBathModal}>
                <ModalHeader tag="h4" toggle={this.handleBedBathModal} className="no-background">
                    {getI18nLocaleString(namespaceList.dynamicBathroom, "bedBath", currentLocale, site)}
                </ModalHeader>
                <ModalBody>
                    <div className="bedbath-dropdown">{options.useAsExactMatch ? exactMatchLayout : layout}</div>
                </ModalBody>
                <ModalFooter>
                    <div className="filter-action-buttons">
                        <a className="action-button__clear" role="button" onClick={options.useAsExactMatch ? this.clearExactBedBathResults : this.clearResults}>
                            {getI18nLocaleString(namespaceList.admin, "clear", currentLocale, site)}
                        </a>
                        <a className="action-button__apply" role="button" onClick={options.useAsExactMatch ? this.applyExactBedBathResults : this.applyResults}>
                            {getI18nLocaleString(namespaceList.admin, "apply", currentLocale, site)}
                        </a>
                    </div>
                </ModalFooter>
            </Modal>
        );
    };

    // eslint-disable-next-line max-lines-per-function
    private handleBathroom = (minBathroom: boolean, increaseBathroom?: boolean, event?: React.ChangeEvent<HTMLInputElement>) => {
        const { options, context, dispatchAction } = this.props;
        const { currentLocale, site } = context;
        const { selectedMinBathroom, selectedMaxBathroom, bathroomMaxLimitErrorAlert, bathroomLimitExceedError } = this.state;

        const bathroom = !increaseBathroom && event ? (+event.target.value <= 0 ? undefined : +event.target.value) : undefined;

        if (minBathroom) {
            const selectedMinBathroomNumber: number | undefined =
                increaseBathroom === undefined && event
                    ? bathroom
                    : increaseBathroom
                    ? selectedMinBathroom
                        ? selectedMinBathroom + 1
                        : 1
                    : selectedMinBathroom && selectedMinBathroom > 1
                    ? selectedMinBathroom - 1
                    : 0;
            if (options.maxBathroomLimit && selectedMinBathroomNumber && selectedMinBathroomNumber > +options.maxBathroomLimit) {
                if (context.alerts && !options.showErrorInWidget) {
                    context.alerts.push({
                        color: "danger",
                        message: getI18nLocaleString(namespaceList.dynamicBedroomBathroom, "bathroomMaxLimitReach", currentLocale, site),
                    });
                } else if (options.showErrorInWidget) {
                    this.setState({ bathroomMaxLimitErrorAlert: true });
                }
                return;
            } else if (bathroomMaxLimitErrorAlert) {
                this.setState({ bathroomMaxLimitErrorAlert: false });
            }

            // min bigger than max
            let setMaxBathroom = false;
            if (selectedMinBathroomNumber && (selectedMaxBathroom || selectedMaxBathroom === 0) && options.isMaxBathroomShown && selectedMinBathroomNumber > selectedMaxBathroom) {
                this.setState({ selectedMaxBathroom: selectedMinBathroomNumber });
                setMaxBathroom = true;
            }

            // When not popup, onclick of plus minus, result should be filtered
            if (!options.useAsPopup) {
                const action: FilterChangeAction = {
                    type: ActionType.FilterChange,
                    filter: setMaxBathroom ? dynamicFilterType.maxBedBath : dynamicFilterType.minbathroom,
                    payload: setMaxBathroom
                        ? {
                              ...this.props.dynamicFilter,
                              minbathroom: selectedMinBathroomNumber,
                              maxbathroom: selectedMinBathroomNumber,
                          }
                        : {
                              minbathroom: selectedMinBathroomNumber,
                          },
                };
                dispatchAction(action);
            }
            this.setState({ selectedMinBathroom: selectedMinBathroomNumber });
        } else {
            const selectedMaxBathroomNumber: number | undefined =
                increaseBathroom === undefined && event
                    ? bathroom
                    : increaseBathroom
                    ? selectedMaxBathroom
                        ? selectedMaxBathroom + 1
                        : 1
                    : selectedMaxBathroom && selectedMaxBathroom > 1
                    ? selectedMaxBathroom - 1
                    : 0;
            if (options.maxBathroomLimit && selectedMaxBathroomNumber && selectedMaxBathroomNumber > +options.maxBathroomLimit) {
                if (context.alerts && !options.showErrorInWidget) {
                    context.alerts.push({
                        color: "danger",
                        message: getI18nLocaleString(namespaceList.dynamicBedroomBathroom, "bedroomMaxLimitReach", currentLocale, site),
                    });
                } else if (options.showErrorInWidget) {
                    this.setState({ bathroomMaxLimitErrorAlert: true });
                }
                return;
            } else if (bathroomMaxLimitErrorAlert) {
                this.setState({ bathroomMaxLimitErrorAlert: false });
            }

            // if max is smaller than min
            if (selectedMaxBathroomNumber && selectedMinBathroom && selectedMaxBathroomNumber < selectedMinBathroom) {
                if (context.alerts && !options.showErrorInWidget) {
                    context.alerts.push({
                        color: "danger",
                        message: getI18nLocaleString(namespaceList.dynamicBedroomBathroom, "cantExceedMin", currentLocale, site),
                    });
                } else if (options.showErrorInWidget) {
                    this.setState({ bathroomLimitExceedError: true });
                }
                return;
            } else if (bathroomLimitExceedError) {
                this.setState({ bathroomLimitExceedError: false });
            }

            if (!options.useAsPopup) {
                const action: FilterChangeAction = {
                    type: ActionType.FilterChange,
                    filter: dynamicFilterType.maxbathroom,
                    payload: {
                        maxbathroom: selectedMaxBathroomNumber,
                    },
                };
                dispatchAction(action);
            }
            this.setState({ selectedMaxBathroom: selectedMaxBathroomNumber });
        }
    };

    private applyResults = () => {
        const { dispatchAction } = this.props;
        const { selectedMaxBathroom, selectedMinBathroom, selectedMaxBedroom, selectedMinBedroom, bedroomMaxLimitErrorAlert, bathroomMaxLimitErrorAlert } = this.state;
        const action: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.maxBedBath,
            payload: {
                ...this.props.dynamicFilter,
                maxbathroom: selectedMaxBathroom || undefined,
                minbathroom: selectedMinBathroom || undefined,
                minbedroom: selectedMinBedroom || undefined,
                maxbedroom: selectedMaxBedroom || undefined,
            },
        };
        dispatchAction(action);
        this.toggle();
        if (bedroomMaxLimitErrorAlert || bathroomMaxLimitErrorAlert) {
            this.setState({ bedroomMaxLimitErrorAlert: false, bathroomMaxLimitErrorAlert: false });
        }
    };

    private applyExactBedBathResults = () => {
        const {
            dispatchAction,
            options: { forBathroomExactMatch, forBedroomExactMatch },
        } = this.props;
        const { exactBathroom, exactBedroom } = this.state;
        const removePriceFilters = { minprice: undefined, maxprice: undefined };
        let bathroomPayload: any = {
            maxbathroom: exactBathroom !== "All" ? +exactBathroom : undefined,
            minbathroom: exactBathroom !== "All" ? +exactBathroom : undefined,
        };
        let bedroomPayload: any = {
            minbedroom: exactBedroom !== "All" ? +exactBedroom : undefined,
            maxbedroom: exactBedroom !== "All" ? +exactBedroom : undefined,
        };

        if (exactBathroom === "All" && forBathroomExactMatch) {
            bathroomPayload = { ...bathroomPayload, ...removePriceFilters };
        }
        if (exactBedroom === "All" && forBedroomExactMatch) {
            bedroomPayload = { ...bedroomPayload, ...removePriceFilters };
        }
        const action = this.getBedBathAction({ ...(forBathroomExactMatch ? bathroomPayload : {}), ...(forBedroomExactMatch ? bedroomPayload : {}) });
        dispatchAction(action);
        this.toggle();
        this.setState({ finalExactBedroom: exactBedroom !== "All" ? +exactBedroom : "", finalExactBathroom: exactBathroom !== "All" ? +exactBathroom : "", displayLabel: null });
    };

    private clearExactBedBathResults = () => {
        const {
            options: { forBathroomExactMatch, forBedroomExactMatch },
            dispatchAction,
        } = this.props;
        const bathroomPayload = {
            maxbathroom: undefined,
            minbathroom: undefined,
        };
        const bedroomPayload = {
            minbedroom: undefined,
            maxbedroom: undefined,
        };
        // Set the previous availability results, clearing all bed-bath filters at the same time
        const action = this.getBedBathAction(
            {
                ...(forBathroomExactMatch ? bathroomPayload : {}),
                ...(forBedroomExactMatch ? bedroomPayload : {}),
            },
            dynamicFilterType.removeBedroomFilters
        );
        dispatchAction(action);
        this.toggle();
        this.setState({ exactBedroom: "All", exactBathroom: "All", finalExactBedroom: "", finalExactBathroom: "", displayLabel: null });
    };

    private clearResults = () => {
        const { bedroomMaxLimitErrorAlert, bathroomMaxLimitErrorAlert } = this.state;
        // Set the previous availability results, clearing all bed-bath filters at the same time
        const bedBathroomFilterAction: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.removeBathroomFilters, // Does the same as "removeBedroomFilters"
            payload: {
                ...this.props.dynamicFilter,
                maxbathroom: undefined,
                minbathroom: undefined,
                minbedroom: undefined,
                maxbedroom: undefined,
            },
        };
        this.props.dispatchAction(bedBathroomFilterAction);
        this.toggle();
        if (bedroomMaxLimitErrorAlert || bathroomMaxLimitErrorAlert) {
            this.setState({ bedroomMaxLimitErrorAlert: false, bathroomMaxLimitErrorAlert: false });
        }
    };

    // eslint-disable-next-line max-lines-per-function
    private getPopupDisplay = () => {
        let { finalSelectedMaxBathroom, finalSelectedMaxBedroom, finalSelectedMinBathroom, finalSelectedMinBedroom } = this.state;
        const { minbathroom, maxbathroom, minbedroom, maxbedroom } = this.props.dynamicFilter;
        const { currentLocale, site } = this.props.context;
        // If component is remounted and dynamic filter has values, updating here
        finalSelectedMaxBathroom = finalSelectedMaxBathroom || maxbathroom;
        finalSelectedMinBathroom = finalSelectedMinBathroom || minbathroom;
        finalSelectedMaxBedroom = finalSelectedMaxBedroom || maxbedroom;
        finalSelectedMinBedroom = finalSelectedMinBedroom || minbedroom;

        let bathroomLabel = "";
        let bedroomsLabel = "";
        if (finalSelectedMaxBathroom || finalSelectedMinBathroom) {
            bathroomLabel =
                finalSelectedMaxBathroom! > 1 || finalSelectedMinBathroom! > 1
                    ? ` ${getI18nLocaleString(namespaceList.dynamicBathroom, "bathrooms", currentLocale, site)}`
                    : ` ${getI18nLocaleString(namespaceList.dynamicBathroom, "bathroom", currentLocale, site)}`;
        }
        if (finalSelectedMaxBedroom || finalSelectedMinBedroom) {
            bedroomsLabel =
                finalSelectedMaxBedroom! > 1 || finalSelectedMinBedroom! > 1
                    ? ` ${getI18nLocaleString(namespaceList.dynamicBedroom, "bedrooms", currentLocale, site)}`
                    : ` ${getI18nLocaleString(namespaceList.dynamicBedroom, "bedroom", currentLocale, site)}`;
        }
        const slash = " / ";
        const dash = " - ";
        const displayLabel =
            finalSelectedMinBathroom && finalSelectedMaxBathroom && finalSelectedMinBedroom && finalSelectedMaxBedroom ? (
                <span>
                    {finalSelectedMinBedroom}
                    {dash}
                    {finalSelectedMaxBedroom}
                    {bedroomsLabel}
                    {slash}
                    {finalSelectedMinBathroom}
                    {dash}
                    {finalSelectedMaxBathroom}
                    {bathroomLabel}
                </span>
            ) : finalSelectedMinBedroom && finalSelectedMaxBedroom && (finalSelectedMinBathroom || finalSelectedMaxBathroom) ? (
                <span>
                    {finalSelectedMinBedroom}
                    {dash}
                    {finalSelectedMaxBedroom}
                    {bedroomsLabel}
                    {slash}
                    {finalSelectedMinBathroom || finalSelectedMaxBathroom}
                    {bathroomLabel}
                </span>
            ) : finalSelectedMinBathroom && finalSelectedMaxBathroom && (finalSelectedMaxBedroom || finalSelectedMinBedroom) ? (
                <span>
                    {finalSelectedMinBedroom || finalSelectedMaxBedroom}
                    {bedroomsLabel}
                    {slash}
                    {finalSelectedMinBathroom}
                    {dash}
                    {finalSelectedMaxBathroom}
                    {bathroomLabel}
                </span>
            ) : finalSelectedMinBathroom && finalSelectedMaxBathroom ? (
                <span>
                    {finalSelectedMinBathroom}
                    {dash}
                    {finalSelectedMaxBathroom}
                    {bathroomLabel}
                </span>
            ) : finalSelectedMinBedroom && finalSelectedMaxBedroom ? (
                <span>
                    {finalSelectedMinBedroom}
                    {dash}
                    {finalSelectedMaxBedroom}
                    {bedroomsLabel}
                </span>
            ) : finalSelectedMinBedroom && finalSelectedMinBathroom ? (
                <span>
                    {finalSelectedMinBedroom}
                    {bedroomsLabel}
                    {slash}
                    {finalSelectedMinBathroom}
                    {bathroomLabel}
                </span>
            ) : (finalSelectedMaxBathroom && finalSelectedMinBedroom) || (finalSelectedMaxBedroom && finalSelectedMinBathroom) ? (
                <span>
                    {finalSelectedMinBedroom || finalSelectedMaxBedroom}
                    {bedroomsLabel}
                    {slash}
                    {finalSelectedMinBathroom || finalSelectedMaxBathroom}
                    {bathroomLabel}
                </span>
            ) : (finalSelectedMaxBathroom || finalSelectedMinBathroom) && (finalSelectedMaxBedroom || finalSelectedMinBedroom) ? (
                <span>
                    {finalSelectedMaxBedroom || finalSelectedMinBedroom}
                    {bedroomsLabel}
                    {slash}
                    {finalSelectedMaxBathroom || finalSelectedMinBathroom}
                    {bathroomLabel}
                </span>
            ) : finalSelectedMaxBedroom || finalSelectedMinBedroom ? (
                <span>
                    {finalSelectedMaxBedroom || finalSelectedMinBedroom}
                    {bedroomsLabel}
                </span>
            ) : finalSelectedMaxBathroom || finalSelectedMinBathroom ? (
                <span>
                    {finalSelectedMaxBathroom || finalSelectedMinBathroom}
                    {bathroomLabel}
                </span>
            ) : (
                ""
            );
        return displayLabel;
    };

    private getExactMatchPopupDisplay = () => {
        let { finalExactBathroom, finalExactBedroom } = this.state;
        const { options } = this.props;
        const { forBathroomExactMatch, isMinBathroomShown, isMaxBathroomShown, forBedroomExactMatch, isMinBedroomShown, isMaxBedroomShown } = options;
        const useForBathrooms = forBathroomExactMatch || isMinBathroomShown || isMaxBathroomShown;
        const useForBedrooms = forBedroomExactMatch || isMinBedroomShown || isMaxBedroomShown;
        const {
            context: { currentLocale, site },
            dynamicFilter: { minbedroom, minbathroom },
        } = this.props;
        if (!finalExactBathroom && minbathroom) {
            finalExactBathroom = minbathroom;
        }
        if (!finalExactBedroom && minbedroom) {
            finalExactBedroom = minbedroom;
        }
        let bathroomLabel = "";
        let bedroomsLabel = "";
        if (finalExactBathroom && useForBathrooms) {
            bathroomLabel =
                +finalExactBathroom > 1
                    ? `${finalExactBathroom} ${getI18nLocaleString(namespaceList.dynamicBathroom, "bathrooms", currentLocale, site)}`
                    : `${finalExactBathroom} ${getI18nLocaleString(namespaceList.dynamicBathroom, "bathroom", currentLocale, site)}`;
        }
        if (finalExactBedroom && useForBedrooms) {
            bedroomsLabel =
                +finalExactBedroom > 1
                    ? `${finalExactBedroom} ${getI18nLocaleString(namespaceList.dynamicBedroom, "bedrooms", currentLocale, site)}`
                    : `${finalExactBedroom} ${getI18nLocaleString(namespaceList.dynamicBedroom, "bedroom", currentLocale, site)}`;
        } else if (finalExactBedroom === 0 || minbedroom === 0) {
            bedroomsLabel = getI18nLocaleString(namespaceList.dynamicBedroomBathroom, "studio", currentLocale, site);
        }
        const slash = " / ";
        let label = null;
        if (bedroomsLabel || bathroomLabel) {
            label = <span>{`${bedroomsLabel} ${bedroomsLabel && bathroomLabel ? slash : ""} ${bathroomLabel}`}</span>;
            if (options.useAsNameListDropdown) {
                bathroomLabel = bathroomLabel === "" ? `${getI18nLocaleString(namespaceList.dynamicBathroom, "bathrooms", currentLocale, site)}` : bathroomLabel;
                bedroomsLabel = bedroomsLabel === "" ? `${getI18nLocaleString(namespaceList.dynamicBedroom, "bedrooms", currentLocale, site)}` : bedroomsLabel;
                label = <label className="filter-data-wrap">{`${useForBathrooms ? bathroomLabel : ""} ${useForBedrooms ? bedroomsLabel : ""}`}</label>;
            }
        }
        return label;
    };

    private getBedBathAction = (payload: any, filter?: dynamicFilterType) => {
        const { dispatchAction, dynamicFilter } = this.props;
        const action: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: filter || dynamicFilterType.maxBedBath,
            payload: {
                ...dynamicFilter,
                ...payload,
            },
        };
        return action;
    };
}

function mapStateToProps(state: State): BedroomBathroomStoreProps {
    return {
        dynamicFilter: state.dynamicFilter,
        availabilityState: state.availabilityState,
    };
}

function mapDispatchToProps(dispatch: Dispatch<FilterChangeAction | AvailabilityAction>): BedroomBathroomDispatchProps {
    return { dispatchAction: dispatch };
}

// eslint-disable-next-line max-len
const BedroomBathroom = connect<BedroomBathroomStoreProps, BedroomBathroomDispatchProps>(mapStateToProps, mapDispatchToProps)(BedroomBathroomWidget);

export const DynamicBedroomBathroomFacet = wrapProps<BedroomBathroomBaseProps>(BedroomBathroom);
